<template>
  <div class="showContainer">
    <div style="overflow-y: scroll;height:100%;width:100%;padding-top:60px" ref="GoTopContent">
    <Title></Title>
      <div class="content">
          <div class="contentForm">
            <h4 style="font-size:24px;font-weight:500;color:#222;margin:40px 0 12px">预约演示</h4>
            <p style="font-size:14px;font-weight:400;color:#222;margin:0 0 40px 0">请完善以下信息，我们将尽快与您联系</p>
            <el-form ref="form" :model="form" label-width="0">
              <el-form-item prop="NAME"  :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
                <el-input v-model="form.NAME" placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item  prop="MOBILE"  :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }, { pattern:/^1[3|4|5|7|8][0-9]{9}$/ ,message: '手机号格式不正确', trigger:'blur'}]">
                <el-input v-model="form.MOBILE" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <!-- <el-form-item prop="MAILBOX" :rules="[{ type: 'email', message: '请输入正确的邮箱地址', trigger:'blur' }]">
                <el-input v-model="form.MAILBOX" placeholder="请输入您的邮箱"></el-input>
              </el-form-item> -->
              <el-form-item prop="DEPT" :rules="[{ required: true, message: '请输入您的单位', trigger: 'blur' }]">
                <el-input v-model="form.DEPT" placeholder="请输入您的单位" ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="formButtom">
                  <button class="formBtn" @click.prevent="submit">提交</button>
                  <span class="spanTip">您提交的信息将会受到严格保密</span>
                </div>
              </el-form-item>
            </el-form>
          </div>
      </div>
    </div>

    <el-dialog title="预约演示" :visible.sync="dialogVisible" width="880px" :before-close="()=>{dialogVisible = false}">
      <div style="text-align:center;padding:50px 0 100px">
        <div class="el-icon-circle-check" style="font-size:105px;color:#00C952"></div>
        <div style="font-size:20px;color:#222;margin-top:13px;margin-bottom:10px">提交成功</div>
        <div style="font-size:16px;color:#666">感谢您的信任与支持，我们将尽快与您取得联系</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { submitForm } from '@/require/index.js'
export default {
  name: 'FreeTrial',
  data () {
    return {
      form: {
        NAME: '',
        MOBILE: '',
        // MAILBOX: '',
        DEPT: ''
      },
      dialogVisible: false
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate(val => {
        if (val) {
          const { NAME, MOBILE, DEPT } = { ...this.form }
          submitForm(NAME, MOBILE, DEPT).then(res => {
            if (res) {
              this.form.NAME = ''
              this.form.MOBILE = ''
              this.form.DEPT = ''
              this.dialogVisible = true
            }
          }).catch(err => {
            this.$message.error(err.msg ? err.msg : err)
          })
        }
      })
    },
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moShow')
      }
    }
  },
  created () {
    // console.log(this.$route.query.MAILBOX)
    // this.form.MAILBOX = this.$route.query.MAILBOX
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
body{
  font-family: "SF Pro SC","HanHei SC","SF Pro Text","Myriad Set Pro","SF Pro Icons","PingFang SC","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.showContainer{
    /* margin-top: 80px;
    height: calc(100vh - 80px);
    min-height: 638px;
    min-width: 1200px; */

  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  background: url('~@/assets/submitBg.png') center no-repeat;
  background-size: cover;
  box-sizing: border-box;
  overflow: hidden;
}
.content{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  /* position: relative; */
}
.contentForm{
  /* position: absolute;
  left: 50%;
  top: 50%; */
  /* margin-top: -319px; */
width: 600px;
height: 500px;
background: rgba(255, 255, 255, 0.8);
border-radius: 4px;
backdrop-filter: blur(10px);
display: flex;
flex-direction: column;
align-items: center;
}
.formBtn{
  width: 360px;
height: 46px;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
color: #FFFFFF;
border: 0;
}
.spanTip{
font-size: 12px;
font-weight: 400;
color: #999999;
}
.formButtom{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
